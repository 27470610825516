@import '../../../styles/variables.scss';

.hero {
    height: 100vh;
}

.content {
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;

    @media (min-width: $desktop) {
        gap: 40px;
    }

    @media (max-height: 470px) {
        gap: 20px;
    }

    &__intro {
        overflow: hidden;

        &-title {
            font-size: 60px;
            font-weight: $fw-semibold;
            margin-left: -5px;

            span {
                display: block;

                @media (max-height: 470px) {
                    display: inline;
                }

                @media (min-width: $desktop) and (max-height: 650px) {
                    display: inline;
                }
            }

            @media (min-width: $tablet) {
                font-size: 80px;
            }

            @media (min-width: $desktop) {
                font-size: 100px;
            }

            @media (min-width: $desktop-l) {
                font-size: 110px;
            }
        }

        &-subtitle {
            font-size: 20px;
            text-transform: uppercase;
            font-weight: $fw-light;
            animation: appear_slide 1.3s;

            @media (min-width: $tablet) {
                font-size: 30px;
            }

            @media (min-width: $desktop) {
                font-size: 40px;
            }

            @media (min-width: $desktop-l) {
                font-size: 50px;
            }

            @media (min-width: $desktop-l) and (max-height: 650px) {
                font-size: 40px;
            }
        }
    }
    
    
    &__details {
        display: flex;
        flex-direction: column;
        gap: 20px;
        
        @media (min-width: $tablet) {
            flex-direction: row;
            justify-content: space-between;
            gap: 200px;
        }

        &-text {
            order: 1;
            font-size: 16px;
            font-weight: $fw-medium;
            max-width: 500px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 10px;
            margin-right: 30px;

            @media (min-width: $tablet) {
                order: 2;
                max-width: 450px;
                font-size: 18px;
            }

            @media (min-width: $desktop) {
                font-size: 24px;
            }

            @media (min-width: $desktop-l) {
                font-size: 26px;
            }

            @media (min-width: $desktop) and (max-height: 650px) {
                font-size: 20px;
            }

            &__about {
                overflow: hidden;

                span {
                    font-weight: $fw-bold;
                }

                p {
                    animation: appear_slide 1.8s;
                }
            }


            &__links {
                display: none;
                
                @media (min-width: $tablet) {
                    display: block;
                }
                
                &-item {
                    font-size: 14px;
                    color: $clr-dark;
                    position: relative;
                    margin-right: 15px;
                    visibility: hidden;
                    animation: appear_fade 1.3s;
                    animation-fill-mode: forwards;

                    @for $i from 1 through 2 {
                        &:nth-child(#{$i}) {
                            animation-delay: $i * 0.3s;
                        }
                    }

                    @media (min-width: $desktop) {
                        font-size: 18px;
                    }

                    @media (min-width: $desktop-l) {
                        font-size: 20px;
                    }

                    &:hover::after {
                        transform: scale(1);
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 1px;
                        width: 100%;
                        background: $clr-dark;
                        transform-origin: left;
                        transform: scale(0,1);
                        transition: 0.3s all;
                    }
                }
            }
        }
    
        &-buttons {
            order: 2;
            display: flex;
            flex-direction: column;
            gap: 15px;

            a {
                visibility: hidden;
                animation: appear_fade 1s;
                animation-fill-mode: forwards;

                @for $i from 1 through 2 {
                    &:nth-child(#{$i}) {
                        animation-delay: 0.8 + $i * 0.2s;
                    }
                }
            }

            @media (min-width: $tablet) {
                order: 1;
            }

            @media (max-height: 470px) {
                gap: 5px;
            }
        }
    }


}

.scroll {
    height: 15vh;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &::after {
        position: absolute;
        content: '';
        height: 5px;
        width: 100%;
        background: $clr-dark;
        top: -5px;
        transform-origin: 0% 100%;
        animation: bar_expand 2s;
    }

    &__text {
        writing-mode: vertical-rl;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: 10px; 
        margin-right: 20px;
        position: relative;
        opacity: 0;
        animation: show_scroll 1s;
        animation-fill-mode: forwards;
        animation-delay: 3s;

        @media (max-height: 470px) {
            font-size: 6px;
        }

        &::after {
            position: absolute;
            content: '';
            background: $clr-dark;
            height: 100%;
            width: 1px;
            top: 0;
            left: -2px;
            opacity: 0.5;
            transform-origin: top;
            animation: underline_scroll 6s infinite ease-in-out;
            animation-delay: 3s;
        }
    }
}

@keyframes show_scroll {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes underline_scroll {
    0% {
      transform: scaleY(0);
    }
    50% {
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(0);
    }
  }
  
  @keyframes bar_expand {
    0%, 15% {
      transform: scale(0,1);
    }
    100% {
      transform: scale(1,1);
    }
  }
  
  @keyframes appear_slide {
    0%, 30% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes appear_fade {
    0%, 50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }