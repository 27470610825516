@import '../../../styles//variables.scss';

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  color: $clr-light;
  font-size: 16px;
  font-weight: $fw-semibold;
  
  background: $clr-dark;
  height: 40px;
  width: 160px;
  
  cursor: pointer;
  position: relative;

  @media (min-width: $tablet) {
    font-size: 18px;
  }

  @media (min-width: $desktop) {
    height: 60px;
    width: 240px;  
    font-size: 24px;
  }

  @media (min-width: $desktop-l) {
    font-size: 26px;
    height: 65px;
    width: 270px;
  }
  
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 5px;
    background: $clr-gray;
    left: 0;
    bottom: 0;
    transform-origin: center;
    transform: scale(0,1);
    transition: all 0.5s;
  }

  &:hover::after {
    transform: scale(1,1);
  }
}
