$clr-light: #FFFFFF;
$clr-dark: #000000;
$clr-gray: #999999;

$ff-regular: 'Raleway', sans-serif;

$fs-regular: 12px;

$fw-light: 100;
$fw-medium: 200;
$fw-semibold: 300;
$fw-bold: 400;
$fw-black: 500;

$mobile: 425px;
$tablet: 768px;
$desktop: 1024px;
$desktop-l: 1200px;

