@import './variables.scss';

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: $clr-light;
  font-family: $ff-regular;
  font-size: $fs-regular;
  font-weight: $fw-medium;
  color: $clr-dark;
}

a {
  text-decoration: none;
}

button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
}
