@import '../../../styles/variables.scss';

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: $tablet) {
    padding: 0 40px;
  }

  @media (min-width: $desktop) {
    padding: 0 72px;
  }
}
